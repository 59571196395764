/*==========================================================================

Template Elements Stylesheet
____________________________________________________________

Author:		Edge Marketing Solutions

===========================================================================*/

@import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,700";
					 $default-font: 'Nunito Sans', sans-serif;
							$light: 300;
						  $regular: 400;
							 $bold: 700;

/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
					$color-primary: #1b9369;
				  $color-secondary: #169895;
				   $color-tertiary: #77e557;
				 $color-quaternary: darken($color-primary, 10%);
					$color-default: #82737b;
							 $lead: $color-primary;

				  $header-bg-color: $color-primary;
			 $banner-overlay-color: rgba($color-secondary, 0.7);
					 $banner-color: white;
				   $slider-overlay: white;
			   $slider-overlay-bar: rgba(255, 255, 255, 0.7);
	   $slider-text-shadow-opacity: 1px 1px 1px rgba(0, 0, 0, 0.7);

/*===========================================================================*/
/*                       [Feature Settings]                                  */
/*===========================================================================*/

	  $feature-image-overlay-color: rgba($color-primary, 0.8);
			  $feature-image-color: white;
		   $feature-image-h1-color: $feature-image-color;
		 $feature-image-lead-color: $feature-image-color;
			$feature-image-a-color: $color-tertiary;
			$feature-image-a-hover: white;

/*===========================================================================*/
/*                    [Navigation Settings]                                  */
/*===========================================================================*/
						$logo-text: $color-default;
				  $logo-text-hover: $color-primary;
				 $logo-text-mobile: $color-default;
		   $logo-text-hover-mobile: $color-primary;

							  $nav: $color-default;
						$nav-hover: $color-tertiary;
					 $nav-bg-color: $color-primary;
			$nav-menu-btn-bg-color: $color-primary;
					   $nav-border: rgba(255, 255, 255, 0.3);
			  $nav-bg-color-mobile: #f0f0f0;
				 $nav-color-mobile: #333333;

//===========================================================================//
//                  [Feature Navigation Settings]                            //
//===========================================================================//
                   $feature-nav-bg: white;
                $feature-nav-color: $color-primary;
                $feature-nav-title: $color-default;
          $feature-nav-title-hover: $color-secondary;
           $feature-nav-icon-hover: $color-tertiary;
			   $feature-span-color: $color-primary;
         $feature-nav-btn-bg-color: $color-primary;
            $feature-nav-btn-color: white;
         $feature-nav-btn-bg-hover: white;
            $feature-nav-btn-hover: white;
      $feature-nav-btn-hover-color: $color-primary;
     $feature-nav-btn-border-color: $color-primary;
$feature-nav-btn-border-color-hover: $color-primary;
		   $feature_nav_item_width: 33.333%;

//===========================================================================//
//                  [Book Button Settings]                                   //
//===========================================================================//
				   $book-btn-color: white;
				   $book-btn-hover: $color-primary;
					  $book-btn-bg: $color-secondary;
				$book-btn-bg-hover: white;
				  $book-btn-border: darken($color-secondary, 10%);
		    $book-btn-border-hover: $color-primary;

/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
				      $download-bg: $color-secondary;
			$download-bg-secondary: $color-tertiary;
				   $download-color: white;
				   $download-title: $color-tertiary;
				  $download-btn-bg: white;
			   $download-btn-color: $color-primary;
			$download-btn-bg-hover: $color-tertiary;
		 $download-btn-color-hover: $color-primary;

/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
			            $footer-bg: darken($color-default, 30%);
					 $footer-color: rgba(white, 0.66);
				   $footer-a-color: $footer-color;
				   $footer-a-hover: rgba($color-tertiary, 1);
					   $row-social: $footer-color;
				 $row-social-hover: $footer-a-hover;
			 $row-social-instagram: $color-default;
// Instagram Colours [#8a3ab9 Purple Violet] [#4c68d7 Blue] [#cd486b Maroon] [#fbad50 Orange] [#fccc63 Yellow] [#bc2a8d Red Violet] [#e95950 Red Orange]
				 		$footer-h5: $color-primary;

/*=========================================================================*/
/* [Global Template Styles] */
/*=========================================================================*/
body {
	color: $color-default;
	font-family: $default-font;
	font-size: 16px;
	font-weight: $regular;
	line-height: 1.7em;
}

h1 {
	color: $color-primary;
	font-size: 60px;
	font-weight: $light;
    
    &.logo-text {
        font-size: 30px;
    
        a {
            color: $logo-text;
            
            &:hover,
            &:active,
            &:focus {
                color: $logo-text-hover;
            }
        }
    }
}

h2 {
	margin: 10px 0;
	color: $color-primary;
	font-size: 30px;
}

h3 {
	margin: 20px 0;
	color: $color-default;
	font-size: 20px;
}

h4 {
	margin: 20px 0 0 0;
	color: $color-default;
	font-size: 20px;
}

h5 {
	margin: 20px 0 0 0;
	font-weight: $bold;
	font-size: 16px;
	color: $color-secondary;
}

p {
	margin-bottom: 20px;
}

.row-spaced {
	margin-bottom: 30px;
}

a
{
	transition: all 150ms ease-in-out;
	color: $color-primary;
    
    &:hover,
    &:active,
    &:focus {
        color: $color-tertiary;
        text-decoration: none;
    }
}


img {
	max-width: 100%;
}

img.svg-responsive {
	width: 100% \9;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	h1 {
		font-size: 40px;
		text-align: center;
	}
	h2 {
		font-size: 24px;
	}
}

@import "./partials/template-3/header";
@import "./partials/template-3/navigation";
@import "./partials/template-3/slider";
@import "./partials/template-3/feature-navigation";
@import "./partials/template-3/page";
@import "./partials/template-3/buttons";
@import "./partials/chiro/download";
@import "./partials/chiro/infosheets";
@import "./partials/template-3/footer";
@import "./partials/chiro/overrides";