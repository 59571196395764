//=========================================================================//
// [Header Template Styles] //
//=========================================================================//

.header {
	width: 100%;
	padding: 40px 0;
	
	.h-logo {
		text-align: center;
		
		img {
			width: 66%;
		}
	}
}

/* Large Desktop */
@media (min-width: 1200px) {
	.header {
		.col-lg-2
		{
			width: 33.33%;
		}
		
		.col-lg-10
		{
			width: 66.66%;
		}
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.header {
		text-align: center;
		
		.h-logo {
			text-align: center;
			
			img {
				max-width: 200px;
			}
		}
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.header {
		padding: 30px 0;
		text-align: center;
		
		.h-logo {
			img {
				max-width: 250px;
			}
		}
	}
}