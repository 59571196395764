//=========================================================================//
// [Page Template Styles] //
//=========================================================================//

.page {
	position: relative;
	width: 100%;
	padding: 0 0 40px 0;
	margin-top: -120px;
	z-index: 100;
	
	&.page-homepage {
		margin: 0;
		padding: 60px 0;
		position: relative;
		z-index: 1;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
		font-size: 16px;
		text-align: center;
		color: $feature-image-color;
		
		h1 {
			color: $feature-image-h1-color;
			font-size: 45px;
		}
		
		h2 {
			color: $feature-image-h1-color;
		}
		
		a {
			color: $feature-image-a-color;
			
			&:hover,
			&:focus,
			&:active {
				color: $feature-image-a-hover;
			}
		}
		
		.lead {
			color: $feature-image-lead-color;
		}
		
		&:before {
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			background-color: $feature-image-overlay-color;
		}
	}
	
	.content {
		padding-top: 160px;
		
		p
		{
			&.lead
			{
				color: $lead;
			}
		}
	}
}

.subpage-banner {
	width: 100%;
	position: relative;
	padding: 150px 0;
	z-index: 1;
	background-size: cover;
	background-position: top center;
	
	&:before {
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: $banner-overlay-color;
	}
	
	h1 {
		margin: 0;
		padding: 0;
		line-height: 1.2em;
		color: $banner-color;
	}
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
	.subpage-banner {
		padding: 100px 0;
		
		h1 {
			font-size: 50px;
		}
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.subpage-banner {
		padding: 80px 0;
		
		h1 {
			font-size: 50px;
		}
	}
	.page {
		margin-top: -100px;
		
		.content {
			padding-top: 140px;
		}
		
		&.page-homepage {
			margin: 0;
		}
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.subpage-banner {
		padding: 60px 0;
		
		h1 {
			font-size: 40px;
			text-align: center;
		}
	}

	.page {
		margin-top: -0px;
		
		.content {
			padding-top: 40px;
		}
	}
}


