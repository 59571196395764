/*==========================================================================

Template Elements Stylesheet
____________________________________________________________

Author:		Edge Marketing Solutions

===========================================================================*/
@import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,700";
/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                       [Feature Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                    [Navigation Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
/*=========================================================================*/
body {
  color: #82737b;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
}

h1 {
  color: #1b9369;
  font-size: 60px;
  font-weight: 300;
}

h1.logo-text {
  font-size: 30px;
}

h1.logo-text a {
  color: #82737b;
}

h1.logo-text a:hover, h1.logo-text a:active, h1.logo-text a:focus {
  color: #1b9369;
}

h2 {
  margin: 10px 0;
  color: #1b9369;
  font-size: 30px;
}

h3 {
  margin: 20px 0;
  color: #82737b;
  font-size: 20px;
}

h4 {
  margin: 20px 0 0 0;
  color: #82737b;
  font-size: 20px;
}

h5 {
  margin: 20px 0 0 0;
  font-weight: 700;
  font-size: 16px;
  color: #169895;
}

p {
  margin-bottom: 20px;
}

.row-spaced {
  margin-bottom: 30px;
}

a {
  transition: all 150ms ease-in-out;
  color: #1b9369;
}

a:hover, a:active, a:focus {
  color: #77e557;
  text-decoration: none;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  h1 {
    font-size: 40px;
    text-align: center;
  }
  h2 {
    font-size: 24px;
  }
}

.header {
  width: 100%;
  padding: 40px 0;
}

.header .h-logo {
  text-align: center;
}

.header .h-logo img {
  width: 66%;
}

/* Large Desktop */
@media (min-width: 1200px) {
  .header .col-lg-2 {
    width: 33.33%;
  }
  .header .col-lg-10 {
    width: 66.66%;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .header {
    text-align: center;
  }
  .header .h-logo {
    text-align: center;
  }
  .header .h-logo img {
    max-width: 200px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    padding: 30px 0;
    text-align: center;
  }
  .header .h-logo img {
    max-width: 250px;
  }
}

.navbar-default {
  margin: 95px 0 0 0;
  min-height: 10px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  font-size: 15px;
}

.navbar-default .navbar-nav {
  float: right;
}

.navbar-default .navbar-nav > li > a {
  padding: 5px 15px;
  color: #82737b;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #77e557;
  background-color: transparent;
}

.navbar-default .navbar-nav > li:first-child > a {
  padding-left: 0;
}

.navbar-default .navbar-nav > li:last-child > a {
  padding-right: 0;
}

.navbar-default .navbar-nav .open > a,
.navbar-default .navbar-nav .active > a,
.navbar-default .navbar-nav .active > a:hover,
.navbar-default .navbar-nav .active > a:focus {
  color: #77e557;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}

.navbar-default .navbar-toggle {
  margin: 0;
  width: 100%;
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-default {
    margin: 50px 0 0 0;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 5px 14px;
    font-size: 13px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-default {
    margin: 30px 0 0 0;
  }
  .navbar-default .navbar-nav {
    float: none;
    display: inline-block;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 5px 16px;
    font-size: 13px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-default {
    margin: 20px 0 0 0;
  }
  .navbar-default .navbar-nav {
    float: none;
  }
  .navbar-default .navbar-nav > li > a {
    padding: 10px 0;
    font-size: 16px;
  }
  .navbar-default .navbar-nav .open > a,
  .navbar-default .navbar-nav .open > a:hover,
  .navbar-default .navbar-nav .open > a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  }
  .navbar-default .navbar-nav .open > a > li > a,
  .navbar-default .navbar-nav .open > a:hover > li > a,
  .navbar-default .navbar-nav .open > a:focus > li > a,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 0;
    text-align: center;
    color: #333333;
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border: none;
    background-color: #f0f0f0;
  }
}

/* Hover Click Fix */
@media (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}

.slider-wrapper {
  position: relative;
  margin-bottom: 30px;
}

.slider-wrapper:after {
  content: ' ';
  width: 70px;
  height: 80px;
  position: absolute;
  bottom: -35px;
  left: 49.66%;
  -ms-transform: translateX(-40%);
  transform: translateX(-40%);
  background-image: url("/storage/media/logo-icon.png");
  background-size: cover;
}

.slider-wrapper .slickSlider img {
  display: none;
}

.slider-wrapper .slickSlider.slick-initialized img {
  display: block;
}

.slider-wrapper .slickSlider .slick-prev {
  left: 10px;
  z-index: 100;
  opacity: 0;
}

.slider-wrapper .slickSlider .slick-next {
  right: 10px;
  z-index: 100;
  opacity: 0;
}

.slider-wrapper .slickSlider:hover .slick-prev,
.slider-wrapper .slickSlider:hover .slick-next {
  opacity: 0.75;
}

.slider-wrapper .slickSlider .slider-overlay {
  position: absolute;
  z-index: 1000;
  width: 100%;
  top: 85%;
  transform: translateY(-100%);
  color: white;
  font-size: 50px;
  text-align: center;
  line-height: 1.1em;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
}

.slider-wrapper .slickSlider .slider-overlay small {
  font-size: 32px;
  color: #1b9369;
}

.feature-navigation {
  width: 100%;
  background-color: white;
  padding: 0;
}

.feature-navigation h2 {
  padding: 0;
  margin: 15px 0;
  line-height: 1.0em;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
}

.feature-navigation h2 a {
  color: #82737b;
}

.feature-navigation h2 a:hover {
  color: #169895;
  text-decoration: none;
}

.feature-navigation .btn {
  margin: 15px 0 0 0;
  border: 1px solid #1b9369;
  color: white;
  background: #1b9369;
}

.feature-navigation .btn:hover {
  background-color: white;
  color: #1b9369;
  border: 1px solid #1b9369;
}

.feature-navigation.subpage {
  height: 12px;
}

.feature-navigation .item {
  width: 33.333%;
  float: left;
  padding: 30px 10px;
  box-sizing: border-box;
  text-align: center;
  color: #1b9369;
  font-size: 18px;
}

.feature-navigation .item span {
  font-size: 60px;
  color: #1b9369;
  transition: all 150ms ease-in-out;
}

.feature-navigation .item p {
  transition: all 150ms ease-in-out;
}

.feature-navigation .item:hover h2,
.feature-navigation .item:hover a {
  color: #169895;
}

.feature-navigation .item:hover span {
  color: #77e557;
}

.feature-navigation .item:hover p {
  color: #82737b;
}

@media (min-width: 1200px) {
  .feature-navigation .item h2 {
    font-size: 28px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .feature-navigation .item {
    font-size: 15px;
  }
  .feature-navigation .item h2 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .feature-navigation .item {
    width: 100%;
    float: left;
    padding: 20px 0;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: none;
    text-align: center;
    color: #82737b;
    font-size: 12px;
  }
  .feature-navigation .item h2 {
    font-size: 18px;
  }
  .feature-navigation .item .btn {
    margin-top: 8px;
    width: 100%;
    text-align: center;
  }
  .feature-navigation .item:last-child {
    border-right: none;
  }
}

.page {
  position: relative;
  width: 100%;
  padding: 0 0 40px 0;
  margin-top: -120px;
  z-index: 100;
}

.page.page-homepage {
  margin: 0;
  padding: 60px 0;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  font-size: 16px;
  text-align: center;
  color: white;
}

.page.page-homepage h1 {
  color: white;
  font-size: 45px;
}

.page.page-homepage h2 {
  color: white;
}

.page.page-homepage a {
  color: #77e557;
}

.page.page-homepage a:hover, .page.page-homepage a:focus, .page.page-homepage a:active {
  color: white;
}

.page.page-homepage .lead {
  color: white;
}

.page.page-homepage:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(27, 147, 105, 0.8);
}

.page .content {
  padding-top: 160px;
}

.page .content p.lead {
  color: #1b9369;
}

.subpage-banner {
  width: 100%;
  position: relative;
  padding: 150px 0;
  z-index: 1;
  background-size: cover;
  background-position: top center;
}

.subpage-banner:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(22, 152, 149, 0.7);
}

.subpage-banner h1 {
  margin: 0;
  padding: 0;
  line-height: 1.2em;
  color: white;
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .subpage-banner {
    padding: 100px 0;
  }
  .subpage-banner h1 {
    font-size: 50px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .subpage-banner {
    padding: 80px 0;
  }
  .subpage-banner h1 {
    font-size: 50px;
  }
  .page {
    margin-top: -100px;
  }
  .page .content {
    padding-top: 140px;
  }
  .page.page-homepage {
    margin: 0;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .subpage-banner {
    padding: 60px 0;
  }
  .subpage-banner h1 {
    font-size: 40px;
    text-align: center;
  }
  .page {
    margin-top: -0px;
  }
  .page .content {
    padding-top: 40px;
  }
}

.btn {
  transition: all 200ms ease-in-out;
}

.btn-primary {
  background-color: #1b9369 !important;
  border-color: #1b9369 !important;
  color: white !important;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #13684a !important;
  border-color: #13684a !important;
  color: white;
}

.btn-primary:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-primary:focus, .btn-primary.focus, .btn-primary:active {
  box-shadow: 0 0 0 0.2rem rgba(27, 147, 105, 0.5) !important;
}

.btn-book {
  background-color: #169895 !important;
  border-color: #106b69 !important;
  color: white !important;
}

.btn-book:not(:disabled):not(.disabled):active {
  background-color: #106b69 !important;
  border-color: #106b69 !important;
  color: white;
}

.btn-book:hover {
  border-color: #1b9369 !important;
  background: white !important;
  color: #1b9369 !important;
}

.btn-book:focus, .btn-book.focus, .btn-book:active {
  box-shadow: 0 0 0 0.2rem rgba(119, 229, 87, 0.5) !important;
}

.btn-success {
  background-color: #1b9369;
  border-color: #0f523b;
}

.btn-success:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-warning {
  background-color: #77e557;
  border-color: #65e241;
  border-radius: 0;
}

.btn-warning:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-phone {
  background-color: #77e557;
  border-color: #65e241;
  border-radius: 0;
  color: white;
  padding: 12px;
}

.btn-phone:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.container-book {
  position: relative;
}

.container-book .book-btn-header {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
}

.container-book .book-btn-header a {
  border: solid 1px #106b69;
  color: white;
  background: #169895;
  padding: 6px 15px;
  font-size: 14px;
  border-radius: 5px;
}

.container-book .book-btn-header a:hover, .container-book .book-btn-header a:active, .container-book .book-btn-header a:focus {
  border-color: #1b9369;
  background: white;
  color: #1b9369;
}

@media (max-width: 767px) {
  .container-book .book-btn-header {
    position: relative;
    width: 100%;
    top: 0;
  }
  .container-book .book-btn-header a {
    width: 100%;
    display: block;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 0;
  }
}

.download {
  width: 100%;
  margin: 0 0 20px 0;
  float: left;
}

.download .icon {
  width: 100%;
  float: left;
  height: 120px;
  background-image: url("../images/ebook-icon.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.download .details {
  width: 100%;
  padding: 15px;
  height: 100%;
  float: left;
  background-color: #169895;
  color: white;
  text-align: center;
}

.download .details h3 {
  margin: 0 0 5px;
  padding: 0;
  color: #77e557;
  line-height: 1.0em;
  font-size: 16px;
  font-weight: bold;
}

.download .details a.btn {
  margin: 8px 0 0 0;
  background-color: white;
  color: #1b9369;
}

.download .details a.btn:hover {
  background-color: #77e557;
  color: #1b9369;
}

.download .details.secondary {
  background-color: #77e557 !important;
}

.download .details.secondary a.btn:hover {
  background-color: #40b91d;
  color: #1b9369;
}

@media (min-width: 1200px) {
  .download .details h3 {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .download {
    height: auto;
  }
  .download .icon {
    width: 100%;
    height: 100px;
  }
  .download .details {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .download .details h3 {
    font-size: 17px;
    font-weight: bold;
  }
}

.infosheets {
  padding: 15px;
  width: 100%;
  float: left;
  background-color: rgba(175, 175, 175, 0.2);
}

.infosheets h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #82737b;
  text-align: center;
  font-weight: bold;
}

.infosheets ul {
  margin: 15px 0 0 0;
  padding: 0;
  list-style-type: none;
}

.infosheets ul > li {
  margin: 2px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.infosheets ul > li a {
  display: block;
  padding: 9px 15px 9px 55px;
  background-image: url("../images/template-1/Adobe_PDF_file_icon_24x24.png");
  background-repeat: no-repeat;
  background-position: 15px center;
  color: #82737b;
}

.infosheets ul > li a:hover {
  text-decoration: none;
  background-color: white;
}

.footer {
  width: 100%;
  padding: 30px 0;
  margin: 0;
  background-color: #312b2e;
  color: rgba(255, 255, 255, 0.66);
  font-size: 12px;
  line-height: 1.75em;
}

.footer .arrow {
  display: none;
}

.footer h5 {
  margin: 0 0 10px 0;
  color: #1b9369;
  font-size: 14px;
}

.footer a {
  color: rgba(255, 255, 255, 0.66);
}

.footer a:hover {
  color: #77e557;
  text-decoration: none;
}

.footer a .btn {
  margin: 0 0 30px 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

.footer a .btn:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer ul > li {
  padding: 5px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer .row-social {
  margin-bottom: 10px;
  font-size: 30px;
  text-align: right;
}

.footer .row-social a {
  color: rgba(255, 255, 255, 0.66);
}

.footer .row-social a.instagram {
  font-size: 3.5rem;
  margin-left: 5px;
  color: #82737b;
}

.footer .row-social a:hover {
  color: #77e557;
}

.logo-chiropractic-iq,
.logo-caa {
  transition: all 150ms ease-in-out;
  width: 100%;
  opacity: 0.5;
  float: right;
}

.logo-chiropractic-iq:hover,
.logo-caa:hover {
  opacity: 1;
}

.logo-caa {
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer h5 {
    margin: 30px 0 10px 0;
  }
  .footer .row-social {
    font-size: 40px;
    margin: 0;
    text-align: center;
  }
  .footer .row-social a.instagram {
    font-size: 4.9rem;
    margin-left: 20px;
  }
  .logo-chiropractic-iq {
    margin: 20px auto;
    width: 50%;
    float: none;
  }
  .logo-caa {
    width: 50%;
    margin: 40px auto 0 auto;
    float: none;
  }
}

#media-query-detector {
  display: none;
  width: 0;
}

@media (min-width: 768px) {
  #media-query-detector {
    width: 768px;
  }
}

@media (min-width: 992px) {
  #media-query-detector {
    width: 992px;
  }
}

@media (min-width: 1200px) {
  #media-query-detector {
    width: 1200px;
  }
}

.row-fix-clear-both {
  clear: both;
}

table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
