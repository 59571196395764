//=========================================================================//
// [Navigation Template Styles] //
//=========================================================================//
.navbar-default {
	margin: 95px 0 0 0;
	min-height: 10px;
	border: none;
	border-radius: 0;
	background-color: transparent;
	background-image: none;
	box-shadow: none;
	font-size: 15px;
	
	.navbar-nav {
		float: right;
		
		> li {
			> a {
				padding: 5px 15px;
				color: $nav;
				
				&:hover,
				&:focus {
					color: $nav-hover;
					background-color: transparent;
				}
			}
			
			&:first-child > a {
				padding-left: 0;
			}
			
			&:last-child > a {
				padding-right: 0;
			}
		}
		
		.open > a,
		.active > a,
		.active > a:hover,
		.active > a:focus {
			color: $nav-hover;
			background-color: transparent;
			background-image: none;
			box-shadow: none;
		}
	}
	
	.navbar-toggle {
		margin: 0;
		width: 100%;
	}
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
	.navbar-default {
		margin: 50px 0 0 0;
		
		.navbar-nav > li > a {
			padding: 5px 14px;
			font-size: 13px;
		}
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.navbar-default {
		margin: 30px 0 0 0;
		
		.navbar-nav {
			float: none;
			display: inline-block;
			
			> li > a {
				padding: 5px 16px;
				font-size: 13px;
			}
		}
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.navbar-default {
		margin: 20px 0 0 0;
		
		.navbar-nav {
			float: none;
			
			> li > a {
				padding: 10px 0;
				font-size: 16px;
			}
			
			.open {
				
				> a,
				> a:hover,
				> a:focus,
				.dropdown-menu {
					border-top: 1px solid rgba(0, 0, 0, 0.05);
					border-left: 1px solid rgba(0, 0, 0, 0.05);
					border-right: 1px solid rgba(0, 0, 0, 0.05);
					
					> li > a {
						padding: 5px 0;
						text-align: center;
						color: $nav-color-mobile;
					}
				}
			}
		}
		
		.navbar-collapse,
		.navbar-form {
			border: none;
			background-color: $nav-bg-color-mobile;
		}
	}
}

/* Hover Click Fix */
@media (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}