//=========================================================================//
// [Slick Slider Styles] //
//=========================================================================//

.slider-wrapper {
	position: relative;
	margin-bottom: 30px;
	
	&:after {
		content: ' ';
		width: 70px;
		height: 80px;
		position: absolute;
		bottom: -35px;
		left: 49.66%;
		-ms-transform: translateX(-40%);
		transform: translateX(-40%);
		background-image: url('/storage/media/logo-icon.png');
		background-size: cover;
	}
	
	.slickSlider {
		
		img {
			display: none;
		}
		
		&.slick-initialized {
			img {
				display: block;
			}
		}
		
		.slick-prev {
			left: 10px;
			z-index: 100;
			opacity: 0;
		}
		
		.slick-next {
			right: 10px;
			z-index: 100;
			opacity: 0;
		}
		
		&:hover {
			.slick-prev,
			.slick-next {
				opacity: 0.75;
			}
		}
		
		.slider-overlay
		{
			position: absolute;
			z-index: 1000;
			width: 100%;
			top: 85%;
			transform: translateY(-100%);
			
			color: $slider-overlay;
			font-size: 50px;
			text-align: center;
			line-height: 1.1em;
			text-shadow: $slider-text-shadow-opacity;
			
			small
			{
				font-size: 32px;
				color: $color-primary;
			}
		}
	}
}